import React, { useState } from "react";
import cta from "../Components/assets/img/cta.webp";
import Swal from "sweetalert2";
import GoToTop from "../GoToTop";
import emailjs from "emailjs-com";
import { db, storage } from "../Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function JobApply() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contact: "",
    address: "",
    city: "",
    country: "",
    cv: null,
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Handle file input separately for size validation
    if (name === "cv" && files && files[0]) {
      const file = files[0];
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB

      if (fileSizeInMB > 10) {
        // File size exceeds 10MB, show error message or handle as needed
        Swal.fire({
          title: "File Size Exceeded",
          html: "Please upload a CV file that is less than 10MB in size.",
          icon: "error",
        });

        // Clear the file input field
        e.target.value = null;

        // Reset the state to not update with invalid file
        return;
      }
    }

    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const { fullName, email, contact, cv } = formData;

    // Upload CV to Firebase Storage
    const storageRef = ref(storage, `cvs/${cv.name}-${contact}`);
    const uploadTask = uploadBytesResumable(storageRef, cv);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Optional: Handle progress
      },
      (error) => {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          html: `Something went wrong, please try again.`,
          icon: "error",
        });
      },
      () => {
        // Handle successful uploads
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // Save form data to Firestore
          db.collection("jobApplications")
            .add({
              fullName,
              email,
              contact,
              cvUrl: url,
              address: formData.address,
              city: formData.city,
              country: formData.country,
            })
            .then(() => {
              // Send email with form data and CV link
              const serviceID = "service_16tftey";
              const templateID = "template_i1g2jkf";
              const userID = "tndxMLtZKtWq-UP5t";

              emailjs
                .send(
                  serviceID,
                  templateID,
                  {
                    fullName,
                    email,
                    contact,
                    cvUrl: url,
                  },
                  userID
                )
                .then(
                  () => {
                    setLoading(false);
                    Swal.fire({
                      title: "Success!",
                      html: `Your application has been submitted.`,
                      icon: "success",
                    }).then(() => {
                      // Reset the form after successful submission
                      setFormData({
                        fullName: "",
                        email: "",
                        contact: "",
                        address: "",
                        city: "",
                        country: "",
                        cv: null,
                      });
                    });
                  },
                  (error) => {
                    setLoading(false);
                    Swal.fire({
                      title: "Error!",
                      html: `Something went wrong, please try again.`,
                      icon: "error",
                    });
                  }
                );
            });
        });
      }
    );
  };

  return (
    <>
      <GoToTop />
      <div
        style={{
          background: `url(${cta}) no-repeat center center`,
          backgroundSize: "cover",
          width: "100%",
          height: "30vh",
          marginTop: "20px",
        }}
      >
        <div
          className="container text-center"
          style={{ color: "white", padding: "50px 10px" }}
        >
          <h2 style={{ fontWeight: "900", marginTop: "50px" }}>
            Want to Join JDB Group?
          </h2>
          <p style={{ color: "white" }}>
            Join our esteemed team and contribute to our continued success!
          </p>
        </div>
      </div>

      <div className="container my-5">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-4 my-2">
              <label className="input_label">
                Full Name<span style={{ color: "red" }}>*</span> :
              </label>
            </div>
            <div className="col-lg-8 my-2">
              <input
                type="text"
                required
                className="form-control"
                placeholder="Enter your full name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 my-2">
              <label className="input_label">
                Email ID<span style={{ color: "red" }}>*</span> :
              </label>
            </div>
            <div className="col-lg-8 my-2">
              <input
                type="email"
                required
                className="form-control"
                placeholder="Enter your email address"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 my-2">
              <label className="input_label">
                Contact<span style={{ color: "red" }}>*</span> :
              </label>
            </div>
            <div className="col-lg-8 my-2">
              <input
                type="tel"
                required
                className="form-control"
                placeholder="+91 0123456789"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 my-2">
              <label className="input_label">
                Address<span style={{ color: "red" }}>*</span> :
              </label>
            </div>
            <div className="col-lg-8 my-2">
              <textarea
                required
                rows="4"
                className="form-control"
                placeholder="Enter your full address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              ></textarea>
              <div className="row">
                <div className="col-lg-6 my-2">
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="Enter City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 my-2">
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="Enter Country"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 my-2">
              <label className="input_label">
                Upload CV<span style={{ color: "red" }}>*</span> :
              </label>
            </div>
            <div className="col-lg-8 my-2">
              <input
                type="file"
                required
                className="form-control"
                name="cv"
                onChange={handleChange}
              />
            </div>
          </div>

          {loading ? (
            <button
              style={{ float: "right", marginTop: "35px" }}
              className="btn btn-secondary"
              type="submit"
              disabled={loading}
            >
              Submitting...Please Wait..
            </button>
          ) : (
            <button
              style={{ float: "right", marginTop: "35px" }}
              className="btn btn-primary"
              type="submit"
            >
              Submit Application
            </button>
          )}
        </form>
      </div>
    </>
  );
}

export default JobApply;
